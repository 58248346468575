import {
  useFloating,
  FloatingPortal,
  FloatingFocusManager
} from "@floating-ui/react";
import {
  faXmark,
  faCopy,
  faArrowDown,
  faRepeat,
  faFileLines,
  faEdit,
  faTrash,
  faEllipsisH
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { LazyMotion, m } from "framer-motion";
import React, { useState, lazy, useEffect } from "react";
import { toast } from "react-toastify";
import useOnClickOutside from "~/hooks/useClickOutside";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import { useAppStore } from "~/store";
import {
  deleteComment,
  postThreadMain,
  editThread as editThreadOperation
} from "~/utils/transactions";
import { FloatingHTMLOverlay } from "../FloatingHTMLOverlay";
import { SmallAvatar16 } from "../format/SmallAvatar";
import { PremiumOnlyBadge } from "../Premium";
import { MarkdownEditorTypes } from "./ThreadsEditor";
import { cache } from "~/utils/cache";
import { createFakeThread } from "~/routes/threads";
import { copyWithRef } from "~/utils/ref";

const loadFeatures = () =>
  import("~/components/framermotion/features").then(res => res.default);
const MarkdownEditor = lazy(() => import("~/components/MarkdownEditor"));

export default function ThreadOptions({
  threadContent,
  onDownVoteCallback
}: FullThreadProps & { onDownVoteCallback?: () => void }) {
  const [
    activeAccount,
    premiumState,
    setPremium,
    isDarkMode,
    setLists,
    loadingIndicators,
    setLoadingIndicators,
    pushToStart
  ] = useAppStore(store => [
    store.account.activeAccount,
    store.account.premium,
    store.account.setPremium,
    store.settings.dark,
    store.utilities.setLists,
    store.loadingIndicator.items,
    store.loadingIndicator.setLoadingIndicators,
    store.threads.pushStartArray
  ]);

  useEffect(() => {
    if (!activeAccount) return;
    cache.getPremium(activeAccount.name).then(premium => {
      setPremium(premium);
    });
  }, [activeAccount]);

  const isMobile = useMediaQuery("(max-width: 720px)");

  const dropdown = React.useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [editThread, setEditThread] = useState(false);

  const toggleDropdown = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setVisible(current => !current);
  };

  const handleDropdownItemAction = (
    event: React.MouseEvent,
    action: string
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (action === "newtab") {
      window.open(
        `https://inleo.io/threads/view/${threadContent.content.author}/${threadContent.content.permlink}`,
        "_blank"
      );
    }

    if (action === "copylink") {
      copyWithRef(
        `https://inleo.io/threads/view/${threadContent.content.author}/${threadContent.content.permlink}`,
        threadContent.content.author
      );
      toast("Copied thread link to clipboard", {
        type: "success",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    }
    if (action === "tweet") {
      const bodyForTwitter = threadContent.content.body
        .replaceAll("#", "")
        .replaceAll("@", "");
      window.open(
        `https://x.com/intent/post?text=${bodyForTwitter}\n&url=https://inleo.io/threads/view/${threadContent.content.author}/${threadContent.content.permlink}`
      );
    }

    if (action === "list") {
      setLists({ modal: true, author: threadContent?.thread?.author });
    }

    if (action === "downvote") {
      onDownVoteCallback?.(true);
    }

    if (action === "rethread") {
      setOpen(true);
    }

    if (action === "edit") {
      if (!premiumState.is_premium) {
        return toast("You need to be premium user to use this feature.", {
          type: "warning",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3_000
        });
      }

      setEditThread(true);
    }
    if (action === "cancel") {
      setVisible(false);
    }
    if (action === "delete") {
      deleteComment({
        author: threadContent.thread?.author || "",
        permlink: threadContent.thread?.permlink || ""
      })
        .then(() => {
          toast(
            `Your thread ${threadContent.content.permlink} is successfully deleted.`,
            {
              type: "success",
              theme: isDarkMode ? "dark" : "light",
              autoClose: 3_000
            }
          );
        })
        .catch(() => {
          toast(`Failed to delete thread ${threadContent.content.permlink}.`, {
            type: "error",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          });
        });
    }

    setVisible(false);
  };

  const handlePost = async (
    body: string,
    pollOptions?: object,
    dimensions?: any
  ) => {
    //if (!premiumState.is_premium) return;

    await postThreadMain(activeAccount!.name, body, pollOptions, dimensions)
      .then(operation => {
        createFakeThread(pushToStart, operation, 0, activeAccount!);
        setEditThread(false);
        setOpen(false);
        toast(`Your thread re-thread is sucessfully published.`, {
          type: "success",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3_000
        });
      })
      .catch(() => {
        toast(`Failed to publish your re-thread.`, {
          type: "error",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3_000
        });
      });
  };

  const handleEdit = (newContent: string) => {
    editThreadOperation(threadContent, newContent)
      .then(() => {
        setEditThread(false);
        toast(
          `Your thread ${threadContent.content.permlink} is successfully updated.`,
          {
            type: "success",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          }
        );
        setLoadingIndicators([
          {
            category: "thread",
            type: "edit",
            id: threadContent.content.permlink
          }
        ]);
      })
      .catch(() => {
        toast(`Failed to update thread ${threadContent.content.permlink}.`, {
          type: "error",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3_000
        });
      });
  };

  useOnClickOutside(dropdown, () => setVisible(false));

  const [open, setOpen] = useState(false);

  const { reference, floating, context } = useFloating({
    open: open,
    onOpenChange: setOpen
  });

  return (
    <div ref={dropdown} className="relative right-0">
      <FloatingPortal>
        {editThread && (
          <FloatingHTMLOverlay
            onClick={ev => ev.stopPropagation()}
            className="bg-overlay z-[1000] flex justify-center py-[10vh]"
            lockScroll
          >
            <div className="relative py-8 pc:py-4 px-6 flex flex-col gap-y-6 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-full h-fit pc:w-4/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full min-w-[340px] pc:min-w-[480px]">
              <header className="flex items-center justify-between pb-4 border-b border-pri dark:border-pri-d">
                <strong className="text-lg">Edit Thread</strong>
                <div
                  onClick={() => setEditThread(false)}
                  className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </header>

              <div className="relative flex flex-col border border-pri dark:border-pri-d border-b-0">
                <React.Suspense fallback={<></>}>
                  <MarkdownEditor
                    type={MarkdownEditorTypes.Thread}
                    initialContent={threadContent.content.body}
                    handlePost={handleEdit}
                  />
                </React.Suspense>
              </div>
            </div>
          </FloatingHTMLOverlay>
        )}
      </FloatingPortal>
      <FloatingPortal>
        <LazyMotion features={loadFeatures}>
          {open && (
            <FloatingFocusManager context={context} initialFocus={-1}>
              <m.div
                initial={{ opacity: 0, scale: 0.98 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.98 }}
                className="fixed inset-0 py-5 px-3 w-full h-full justify-center items-center bg-black/30 z-[1000] overflow-y-auto overflow-x-hidden"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="flex flex-col min-w-[340px] max-w-[calc(100vw-12px)] sm:min-w-[540px] sm:max-w-[640px] mx-auto py-5 px-6 rounded-xl drop-shadow-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)]">
                  <header className="flex items-center justify-between w-full border-b border-pri dark:border-pri-d pb-4">
                    <span className="font-bold text-xl">Re-thread</span>
                    <div
                      onClick={() => setOpen(false)}
                      className="h-10 w-10 flex items-center justify-center rounded-full border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </header>

                  <div className="flex flex-1 flex-col pt-4">
                    <MarkdownEditor
                      type={MarkdownEditorTypes.Thread}
                      initialContent={`https://inleo.io/threads/view/${threadContent?.content?.author}/${threadContent?.content?.permlink}`}
                      handlePost={handlePost}
                      className="border-b-0 px-0"
                    />
                  </div>
                </div>
              </m.div>
            </FloatingFocusManager>
          )}
        </LazyMotion>
      </FloatingPortal>

      <FloatingPortal>
        {visible && isMobile && (
          <div className="fixed h-[100vh] flex flex-col justify-between right-0 left-0 bottom-[53px] z-[10000]">
            <div className="flex h-fit" />
            <FloatingHTMLOverlay
              ref={dropdown}
              onClick={ev => ev.stopPropagation()}
              className={classNames(
                `z-[999] flex flex-col justify-between items-center gap-6 pt-3 px-5 h-fit bottom-0 overflow-hidden border-t border-transparent
                  rounded-3xl rounded-bl-none rounded-br-none pb-2
                font-semibold text-xl relative overflow-y-auto animate-slide-to-bottom
              bg-pri dark:bg-pri-d border border-pri dark:border-pri-d
              drop-shadow-[0_-20px_48px_rgb(0_0_0_/_50%)] shadow-[0px_-6px_12px_rgb(255_255_255_/_12%)]
              `,
                {
                  hidden: !visible
                }
              )}
            >
              <span className="text-2xl font-bold py-4 self-start">
                Thread Options
              </span>

              <div className="flex flex-col items-center gap-y-2.5">
                <SmallAvatar16 author={threadContent?.content?.author} />
                <span>
                  {threadContent?.thread?.displayName ||
                    threadContent?.content?.author}
                </span>
              </div>
              <hr className="w-full border-pri/50 dark:border-pri-d/50" />
              {/* public actions */}
              <div
                className="flex items-center w-full p-3 pt-4 text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "copylink")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
                Copy Link
              </div>

              <div
                className="flex items-center w-full p-3 pt-4 text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "newtab")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
                Open in New Tab
              </div>

              <div
                className="flex items-center w-full p-3 text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "downvote")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faArrowDown} />
                </span>
                Downvote
              </div>

              <div
                className="flex items-center w-full p-3 text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "rethread")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faRepeat} />
                </span>
                Re-thread
              </div>

              <div
                className="flex items-center w-full p-3 text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "tweet")}
              >
                <span className="flex w-6 mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    fill="currentColor"
                    className="w-[14px] h-[14px]"
                  >
                    <g>
                      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                    </g>
                  </svg>
                </span>
                Tweet
              </div>

              {/* premium actions */}
              {premiumState.is_premium && (
                <div
                  className="flex items-center w-full p-3 text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                  onClick={event => handleDropdownItemAction(event, "list")}
                >
                  <span className="flex w-6 mr-2">
                    <FontAwesomeIcon icon={faFileLines} />
                  </span>
                  Add to Lists
                </div>
              )}

              {/* private actions (owner) */}
              {threadContent.thread.author === activeAccount?.name && (
                <React.Fragment>
                  <hr className="bg-pri-d dark:bg-pri my-2 opacity-10" />

                  <div
                    className="flex items-center w-full p-3 text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                    onClick={event => handleDropdownItemAction(event, "edit")}
                  >
                    <span className="flex w-6 mr-2">
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                    Edit
                    <PremiumOnlyBadge className="ml-auto" />
                    <FloatingPortal>
                      {editThread && (
                        <FloatingHTMLOverlay
                          onClick={ev => ev.stopPropagation()}
                          className="bg-overlay z-[1000] flex justify-center py-[10vh]"
                          lockScroll
                        >
                          <div className="relative py-4 px-6 flex flex-col gap-y-6 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-10/12 h-fit pc:w-4/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full min-w-[340px] pc:min-w-[480px]">
                            <header className="flex items-center justify-between pb-4 border-b border-pri dark:border-pri-d">
                              <strong className="text-lg">Edit Thread</strong>
                              <div
                                onClick={() => setEditThread(false)}
                                className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
                              >
                                <FontAwesomeIcon icon={faXmark} />
                              </div>
                            </header>

                            <div className="relative flex flex-col border border-pri dark:border-pri-d border-b-0">
                              <MarkdownEditor
                                type={MarkdownEditorTypes.Thread}
                                initialContent={threadContent.content.body}
                                handlePost={handleEdit}
                              />
                            </div>
                          </div>
                        </FloatingHTMLOverlay>
                      )}
                    </FloatingPortal>
                  </div>

                  <div
                    className="flex items-center w-full p-3 text-red-500 dark:text-red-500 border-red-400 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                    onClick={event => handleDropdownItemAction(event, "delete")}
                  >
                    <span className="flex w-6 mr-2">
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                    Delete
                  </div>
                </React.Fragment>
              )}

              <div
                className="flex items-center w-full p-4 mb-2 justify-center rounded-xl border border-pri dark:border-pri-d hover:bg-pri-d/[0.075] dark:hover:bg-pri/[0.075] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "cancel")}
              >
                <span className="self-center font-medium text-pri/80 dark:text-pri-d/80">
                  Cancel
                </span>
              </div>
            </FloatingHTMLOverlay>
          </div>
        )}
      </FloatingPortal>
      <button
        style={{ zIndex: 1 }}
        type="button"
        aria-label="Thread Options"
        className={classNames(
          "z-10 relative flex justify-center items-center w-12 h-12 sm:w-10 sm:h-10 rounded-full hover:bg-pri-d/[.1] dark:hover:bg-pri/[.1] transition-colors",
          {
            "bg-pri-d/[.1] dark:bg-pri/[.1]": visible
          }
        )}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          toggleDropdown(e);
        }}
        data-prevent-routing
      >
        <FontAwesomeIcon
          icon={faEllipsisH}
          size="lg"
          className="pointer-events-none select-none"
        />
      </button>

      {isMobile === false && visible && (
        <div
          className={classNames(
            "absolute top-[100%] w-max min-w-[180px] right-0 mt-2 p-1 flex flex-col rounded-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d z-[105] drop-shadow-lg shadow-[0_0_12px_rgb(255_255_255_/_12%)]",
            {
              hidden: !visible
            }
          )}
        >
          {/* public actions */}
          <div
            className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
            onClick={event => handleDropdownItemAction(event, "copylink")}
          >
            <span className="flex w-6 mr-2">
              <FontAwesomeIcon icon={faCopy} />
            </span>
            Copy Link
          </div>

          <div
            className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
            onClick={event => handleDropdownItemAction(event, "newtab")}
          >
            <span className="flex w-6 mr-2">
              <FontAwesomeIcon icon={faCopy} />
            </span>
            Open in New Tab
          </div>

          <div
            className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
            onClick={event => handleDropdownItemAction(event, "downvote")}
          >
            <span className="flex w-6 mr-2">
              <FontAwesomeIcon icon={faArrowDown} />
            </span>
            Downvote
          </div>

          <div
            className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
            onClick={event => handleDropdownItemAction(event, "rethread")}
          >
            <span className="flex w-6 mr-2">
              <FontAwesomeIcon icon={faRepeat} />
            </span>
            Re-thread
          </div>

          <div
            className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
            onClick={event => handleDropdownItemAction(event, "tweet")}
          >
            <span className="flex w-6 mr-2">
              <svg
                viewBox="0 0 24 24"
                aria-hidden="true"
                fill="currentColor"
                className="w-[14px] h-[14px]"
              >
                <g>
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                </g>
              </svg>
            </span>
            Tweet
          </div>

          {/* premium actions */}
          {premiumState.is_premium && (
            <div
              className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
              onClick={event => handleDropdownItemAction(event, "list")}
            >
              <span className="flex w-6 mr-2">
                <FontAwesomeIcon icon={faFileLines} />
              </span>
              Add to Lists
            </div>
          )}

          {/* private actions (owner) */}
          {threadContent.thread.author === activeAccount?.name && (
            <React.Fragment>
              <hr className="bg-pri-d dark:bg-pri my-2 opacity-10" />

              <div
                className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "edit")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faEdit} />
                </span>
                Edit
                <PremiumOnlyBadge className="ml-auto" />
              </div>

              <div
                className="flex items-center w-full p-3 font-semibold text-sm text-red-500 dark:text-red-500 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "delete")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faTrash} />
                </span>
                Delete
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}

