import { faComment } from "@fortawesome/free-regular-svg-icons";
import {
  faCaretDown,
  faCaretUp,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "@remix-run/react";
import classNames from "classnames";
import React, { lazy, Suspense, useEffect, useMemo, useRef } from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { useScotContent } from "~/hooks/contentHooks";
import { PostContent, PostFooter } from "~/components/format/PostMisc";
import { PostInteractions } from "~/components/posts/PostInteractions";
import { AuthorPerm, fetchDiscussion, HiveContent } from "~/utils/hive";
import { postThreadReply } from "~/utils/transactions";
import LikeCount, {
  DownVoteSlider,
  LikeSlider,
  useLiked,
  useLikeToggleState
} from "../format/LikeCount";
import Reputation from "../format/Reputation";
import { SmallAvatar12 } from "../format/SmallAvatar";
import IconText from "../IconText";
import Payout from "../Payout";
import { TimeSincePublish } from "../TimeSincePublish";
import DisplayName from "../format/DisplayName";
import { useAppStore } from "~/store";
import { Spinner } from "../format/Spinner";
import { cn } from "~/utils/cn";
import { motion, AnimatePresence } from "framer-motion";
import { MarkdownEditorTypes } from "~/components/MarkdownEditor";

const MarkdownEditor = lazy(() => import("~/components/MarkdownEditor"));
const ProfileLink = lazy(() => import("~/components/ProfileLink"));

export const sortReplies = (repliesFetched, sortingOption, authorPerm) => {
  let replies_keys_list =
    repliesFetched[`${authorPerm?.author}/${authorPerm?.permlink}`]?.replies ||
    [];

  // sort replies keys  with object reference

  replies_keys_list.sort &&
    replies_keys_list.sort((a, b) => {
      let a_obj = repliesFetched[a];
      let b_obj = repliesFetched[b];
      switch (sortingOption) {
        case "REWARD":
          return (
            (b_obj?.pending_payout_value || 0) -
            (a_obj?.pending_payout_value || 0)
          );
        case "AUTHOR_VOTE":
          return (b_obj?.author_vote || 0) - (a_obj?.author_vote || 0);
        case "LATEST":
          return (
            new Date(b_obj?.created).getTime() -
            new Date(a_obj?.created).getTime()
          );
        case "OLDEST":
          return (
            new Date(a_obj?.created).getTime() -
            new Date(b_obj?.created).getTime()
          );
        case "REPUTATION":
          return b_obj?.author_reputation - a_obj?.author_reputation;
        default:
          return 0;
      }
    });

  // sort replies keys with object reference
  let sorted_replies = replies_keys_list.map(key => repliesFetched[key]);
  const new_replies = {
    ...repliesFetched,
    [`${authorPerm?.author}/${authorPerm?.permlink}`]: {
      ...repliesFetched[`${authorPerm?.author}/${authorPerm?.permlink}`],
      replies: sorted_replies.map(reply => `${reply.author}/${reply.permlink}`)
    }
  };

  return new_replies;
};

interface CommentsProps {
  authorPerm: AuthorPerm;
  depth: boolean;
  replies: string[];
  scroll?: boolean;
}

export default function Comments({
  authorPerm,
  depth,
  replies,
  scroll = false
}: CommentsProps) {
  const commentsRef = useRef<HTMLDivElement>(null);

  const [repliesFetched, setRepliesFetched] = useState<HiveContent[]>(replies);
  const [fakeComment, setFakeComment] = useState();
  const activeAccount = useAppStore(store => store.account.activeAccount);

  const [showDisplayOptions, setShowDisplayOptions] = useState(false);
  const [sortingOption, setSortingOption] = useState("LATEST");
  const handleSortingChange = async (option: string) => {
    setSortingOption(option);
    setShowDisplayOptions(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setSortingOption("LATEST");
    }, 200);
  }, [authorPerm]);

  const options = ["REWARD", "AUTHOR_VOTE", "LATEST", "OLDEST", "REPUTATION"];

  useEffect(
    () =>
      void (async function () {
        if (repliesFetched === undefined) {
          try {
            let repliesFetched = await fetchDiscussion(authorPerm);
            //object to array
            setRepliesFetched(
              sortReplies(repliesFetched, sortingOption, authorPerm)
            );
          } catch {
            setRepliesFetched([]);
            console.log("Error while loading replies from nodes.");
          }
        }
      })(),
    [setRepliesFetched, authorPerm, repliesFetched]
  );

  useEffect(() => {
    if (repliesFetched && sortingOption) {
      const new_replies = sortReplies(
        repliesFetched,
        sortingOption,
        authorPerm
      );

      setRepliesFetched(new_replies);
    }
  }, [sortingOption]);

  const handlePost = async (body: string) => {
    setFakeComment({
      replyContent: {
        ...fakeComment,
        author: activeAccount!.name?.replaceAll("@", ""),
        body
      },
      author: activeAccount
    });
    postThreadReply(activeAccount!.name.replaceAll("@", ""), body, {
      author: authorPerm?.author,
      permlink: authorPerm?.permlink
    });
  };

  const permlink = window.location.pathname.split("/")[2];
  const isParent = permlink === authorPerm?.permlink;

  //if (!revealComments) return <RevealComments setRevealComments={setRevealComments}/>

  useEffect(() => {
    if (repliesFetched !== undefined && scroll) {
      commentsRef.current?.scrollIntoView();
    }
  }, [repliesFetched]);

  return (
    <div ref={commentsRef} id="comment" className="relative">
      <React.Fragment>
        {fakeComment && (
          <Comment
            replyContent={fakeComment.replyContent}
            author={fakeComment.author}
          />
        )}
        {activeAccount && !depth && (
          <div className="flex flex-col gap-y-5 px-5 py-5 border-b border-pri/50 dark:border-pri-d/50">
            <strong className="text-2xl font-bold">Share your thoughts</strong>

            <MarkdownEditor
              type={MarkdownEditorTypes.Comment}
              handlePost={handlePost}
              className="border-none bg-pri-d/[.03] dark:bg-pri/[.03] rounded-lg"
            />
          </div>
        )}
        <div className="static">
          {isParent && (
            <motion.div className="w-full h-12 relative justify-end flex">
              <button
                type="button"
                // disabled={loadedThreads.length === 0}
                className="z-[10] disabled:brightness-50 disabled:cursor-not-allowed flex cursor-pointer items-center py-2.5 px-5 gap-x-3 text-pri dark:text-pri-d bg-gray-200 dark:bg-zinc-700 rounded-3xl hover:bg-gray-300 dark:hover:bg-zinc-700/[.75] transition-colors duration-150"
                onClick={() => setShowDisplayOptions(current => !current)}
              >
                <span>Sort by {sortingOption}</span>
                <FontAwesomeIcon icon={faCaretDown} />
              </button>
              <AnimatePresence mode="popLayout">
                {showDisplayOptions && (
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="absolute top-12
                 w-[10rem]
                z-[10]
                bg-white dark:bg-zinc-800 border border-gray-200 dark:border-zinc-700 rounded-lg shadow-lg
                py-2 px-3
                
                "
                  >
                    {options.map((option, index) => (
                      <button
                        key={index}
                        onClick={() => handleSortingChange(option)}
                        className="flex items-center gap-x-3   rounded-3xl  text-gray-700 dark:text-gray-300 
                      hover:bg-gray-100 dark:hover:bg-zinc-700/[.75]  text-sm font-semibold
                       disabled:brightness-50 disabled:cursor-not-allowed cursor-pointer  py-2.5 px-5 flex-col  transition-colors duration-150"
                      >
                        <span>{option}</span>
                      </button>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          )}
          <Suspense fallback={<Spinner text={""}></Spinner>}></Suspense>
          {repliesFetched && (
            <Virtuoso
              useWindowScroll
              className="flex flex-col gap-6"
              data={
                repliesFetched[`${authorPerm?.author}/${authorPerm?.permlink}`]
                  ?.replies
              }
              increaseViewportBy={{ bottom: 500 }}
              itemContent={(i: number, reply: any) => (
                <Comment
                  replyContent={repliesFetched[reply]}
                  last={i === replies?.length - 1}
                  replies={repliesFetched}
                />
              )}
            />
          )}
        </div>
      </React.Fragment>
    </div>
  );
}

interface RevealCommentsProps {
  setRevealComments: Dispatch<SetStateAction<boolean>>;
}

export function RevealComments({ setRevealComments }: RevealCommentsProps) {
  return (
    <div className="p-8 m-auto text-lg">
      There are more than 10 comments on this post.{" "}
      <b onClick={() => setRevealComments}>Click to reveal</b>
    </div>
  );
}

interface Comment {
  replyContent: HiveContent;
  author: any;
  last: Number;
  replies: [];
}

export const Comment = ({
  replyContent,
  author,
  last,
  replies
}: Comment): React.ReactElement | null => {
  const activeAccount = useAppStore(store => store.account.activeAccount);

  const [likeToggle, setLikeToggle] = useLikeToggleState();
  const isActive = activeAccount !== null;
  const [state, scotContent, setScotContent] = useScotContent(replyContent);
  const [markdownActive, setMarkdownActive] = useState<Boolean>(false);
  const [downvoteToggle, setDownvoteToggle] = useState(false);

  const liked = useLiked(activeAccount?.name ?? null, scotContent);

  const [fakeComment, setFakeComment] = useState();
  const [fakeAuthor, setFakeAuthor] = useState(null);

  const handlePost = async (body: string) => {
    setFakeComment({
      replyContent: {
        ...fakeComment,
        author: activeAccount!.name?.replaceAll("@", ""),
        body
      },
      author: activeAccount
    });
    setMarkdownActive(false);
    postThreadReply(activeAccount!.name.replaceAll("@", ""), body, {
      author: replyContent?.author,
      permlink: replyContent?.permlink
    });
  };

  /*let posting_json_metadata;

  try {
    posting_json_metadata =
      typeof author.posting_json_metadata === "string"
        ? (author.posting_json_metadata !== "" && JSON.parse(author.posting_json_metadata)?.profile) || {}
        : author.posting_json_metadata;
  } catch (e) {
    console.error("Error parsing metadata:", {e, posting_json_metadata, coming:  author.posting_json_metadata, author});
  }*/

  const { author: root_author } = useParams();

  // useEffect(() => {

  // }, [replyContent])

  // fake comment
  // const account = cache.getAccount(replyContent.author);

  const PostOptions = lazy(() => import("../posts/PostOptions"));

  const paidOut = useMemo(
    () => new Date(replyContent.payout_at) < new Date(),
    [replyContent.permlink]
  );

  return (
    <div
      className={cn(
        "relative pt-4 pb-2 pl-4 pr-4 duration-100 border-b border-pri/50 dark:border-pri-d/50",
        {
          "border-b border-b-pri/50 dark:border-b-pri-d/50":
            !last && replyContent?.depth === 0
        },
        { "pb-8": last },
        {
          "border-l-2 border-l-pri/50 dark:border-l-pri-d/50 border-b-0":
            replyContent?.depth! > 1
        }
      )}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-1 flex-row justify-between items-center gap-2 pl-2">
          <div className="flex items-center gap-x-1.5">
            <div className="relative">
              <SmallAvatar12 author={replyContent?.author} />
              <div className="absolute -bottom-1 -right-1">
                <Reputation
                  reputation={
                    replyContent?.author_reputation || +author?.reputation
                  }
                  calculated={!!replyContent?.author_reputation}
                />
              </div>
            </div>
            <div className="flex flex-col pl-2 gap-0.5 justify-center">
              <Suspense>
                <ProfileLink
                  accountName={replyContent?.author}
                  referrer={replyContent?.permlink}
                >
                  <DisplayName
                    name={replyContent?.author}
                    className="text-md font-semibold"
                  />
                  {`@${replyContent?.author}` === root_author && (
                    <span className="inline-flex items-center gap-x-1 ml-3 px-1.5 py-0.5 rounded-lg text-xxs uppercase font-semibold bg-acc/[.15] text-acc">
                      <FontAwesomeIcon icon={faStar} size="sm" fixedWidth />
                      Author
                    </span>
                  )}
                </ProfileLink>
              </Suspense>
              <TimeSincePublish
                publishTime={replyContent?.created || ""}
                utc={true}
                className="leading-none mt-0.5"
              />
            </div>
          </div>

          <Suspense fallback={<></>}>
            <PostOptions
              postContent={replyContent}
              onDownVoteCallback={setDownvoteToggle}
            />
          </Suspense>
        </div>
      </div>
      <PostContent
        content={replyContent?.body}
        className="pl-2 pr-4 my-0 pt-4 text-sm font-normal leading-[1.5]"
      />
      <PostFooter>
        <PostInteractions className="relative py-2">
          {/* <div className="flex flex-row relative text-lg rounded-xl py-1 items-center justify-center gap-3"> */}
          {!replyContent?.fake && (
            <div className="flex items-center gap-x-5">
              <LikeCount
                likeCount={
                  scotContent?.active_votes?.length ??
                  replyContent?.active_votes?.length
                }
                scotContent={scotContent}
                liked={liked}
                setLikeToggle={isActive ? setLikeToggle : undefined}
                paidOut={paidOut}
                className={
                  paidOut && "opacity-25 select-none pointer-events-none"
                }
              />
              <ReplyCount
                replyCount={replyContent?.children}
                postContent={replyContent}
                setMarkdownActive={setMarkdownActive}
              />
              {/* <span
                onClick={() => setMarkdownActive(cur => !cur)}
                className="items-center py-0.5 pl-0.5 pr-2 rounded-full text-gray-500 dark:text-zinc-500 hover:bg-acc/[.15] dark:hover:bg-acc/[.15] hover:text-acc dark:hover:text-acc transition-colors cursor-pointer"
              >
                Reply
              </span> */}
            </div>
          )}
          {/* </div> */}
          {
            <Payout
              state={state}
              scotContent={scotContent}
              hiveContent={replyContent}
            />
          }
          {isActive && (
            <LikeSlider
              likeToggle={likeToggle}
              setLikeToggle={setLikeToggle}
              setScotContent={setScotContent}
              accountName={activeAccount?.name}
              authorPerm={replyContent}
            />
          )}

          <DownVoteSlider
            likeToggle={downvoteToggle}
            setLikeToggle={setDownvoteToggle}
            setScotContent={setScotContent}
            accountName={activeAccount?.name}
            authorPerm={replyContent}
          />
        </PostInteractions>
        {markdownActive && (
          <MarkdownEditor
            type={MarkdownEditorTypes.Comment}
            handlePost={handlePost}
          />
        )}
        <div>
          <div className="pl-4">
            {fakeComment && (
              <Comment
                replyContent={fakeComment.replyContent}
                author={fakeComment.author}
                last={undefined}
                replies={[]}
              />
            )}
            <Comments
              authorPerm={
                {
                  author: replyContent?.author,
                  permlink: replyContent?.permlink
                } as unknown as AuthorPerm
              }
              replies={replies}
              depth={true}
            />
          </div>
        </div>
      </PostFooter>
    </div>
  );
};

interface ReplyCountProps {
  replyCount: number;
  postContent?: HiveContent;
  setMarkdownActive?: SetStateAction<Boolean>;
}

export const ReplyCount = ({
  replyCount,
  postContent,
  setMarkdownActive
}: ReplyCountProps) => {
  const activeAccount = useAppStore(store => store.account.activeAccount);

  const handleClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    if (activeAccount === null) {
      return;
    }
    if (postContent === undefined) {
      return;
    }
    //@ts-ignore
    setMarkdownActive((markdownActive: boolean) => !markdownActive);
  };

  return (
    <div
      onClick={event => handleClick(event)}
      className="group transition-colors duration-150"
    >
      <IconText>
        <div className="flex justify-center items-center rounded-full w-9 h-9 sm:w-8 sm:h-8 group-hover:bg-acc/[.15] group-hover:text-acc transition-all duration-150">
          <FontAwesomeIcon icon={faComment} className="w-5 h-5" fixedWidth />
        </div>
        <span className="pl-1 font-normal text-lg sm:text-base group-hover:text-acc transition-all duration-150">
          {replyCount || 0}
        </span>
      </IconText>
    </div>
  );
};
