import {
  useFloating,
  flip,
  hide,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingFocusManager
} from "@floating-ui/react";
import {
  faXmark,
  faCopy,
  faArrowDown,
  faRepeat,
  faFileLines,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { lazy, memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppStore } from "~/store";
import {
  deleteComment,
  postThreadMain,
  editThread as editThreadOperation
} from "~/utils/transactions";
import { FloatingHTMLOverlay } from "../FloatingHTMLOverlay";
import { MarkdownEditorTypes } from "../publish/MarkdownEditorTypes";
import { PremiumOnlyBadge } from "../Premium";
import { cache } from "~/utils/cache";
import { createFakeThread } from "~/routes/threads";
import MarkdownEditor from "~/components/MarkdownEditor";
import { copyWithRef } from "~/utils/ref";

const ControlledThreadOptions = memo(
  ({
    threadContent,
    open,
    setOpen,
    cursor,
    onDownVoteCallback
  }: FullThreadProps & {
    open: boolean;
    setOpen: (open: boolean) => void;
    cursor: { x: number; y: number };
    onDownVoteCallback?: () => void;
  }) => {
    if (!open) return;
    const [
      activeAccount,
      premiumState,
      setPremium,
      isDarkMode,
      setLists,
      pushToStart
    ] = useAppStore(store => [
      store.account.activeAccount,
      store.account.premium,
      store.account.setPremium,
      store.settings.dark,
      store.utilities.setLists,
      store.threads.pushStartArray
    ]);

    const [reThread, setReThread] = useState(false);
    const [editThread, setEditThread] = useState(false);

    const handleDropdownItemAction = (
      event: React.MouseEvent,
      action: string
    ) => {
      event.preventDefault();
      event.stopPropagation();

      setOpen(false);

      if (action === "newtab") {
        window.open(
          `https://inleo.io/threads/view/${threadContent?.content?.author}/${threadContent?.content?.permlink}`,
          "_blank"
        );
      }

      if (action === "copylink") {
        copyWithRef(
          `https://inleo.io/threads/view/${threadContent?.content?.author}/${threadContent?.content?.permlink}`,
          threadContent?.content?.author
        );
        toast("Copied thread link to clipboard", {
          type: "success",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3_000
        });
      }
      if (action === "tweet") {
        const bodyForTwitter = threadContent?.content?.body
          .replaceAll("#", "")
          .replaceAll("@", "");
        window.open(
          `https://x.com/intent/post?text=${bodyForTwitter}\n&url=https://inleo.io/threads/view/${threadContent.content.author}/${threadContent.content.permlink}`
        );
      }

      if (action === "list") {
        setLists({ modal: true, author: threadContent.thread.author });
      }

      if (action === "downvote") {
        onDownVoteCallback?.(true);
      }
      if (action === "rethread") {
        setReThread(true);
      }
      if (action === "edit") {
        if (!premiumState?.is_premium) {
          return toast("You need to be premium user to use this feature.", {
            type: "warning",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          });
        }
        setEditThread(true);
      }
      if (action === "delete") {
        deleteComment({
          author: threadContent?.thread?.author || "",
          permlink: threadContent?.thread?.permlink || ""
        })
          .then(() => {
            toast(
              `Your thread ${threadContent?.content?.permlink} is successfully deleted.`,
              {
                type: "success",
                theme: isDarkMode ? "dark" : "light",
                autoClose: 3_000
              }
            );
          })
          .catch(() => {
            toast(
              `Failed to delete thread ${threadContent?.content?.permlink}.`,
              {
                type: "error",
                theme: isDarkMode ? "dark" : "light",
                autoClose: 3_000
              }
            );
          });
      }
    };

    useEffect(() => {
      if (!activeAccount) return;
      cache.getPremium(activeAccount.name).then(premium => {
        setPremium(premium);
      });
    }, [activeAccount]);

    const handlePost = async (
      body: string,
      pollOptions?: object,
      dimensions?: any
    ) => {
      //if (!premiumState.is_premium) return;

      await postThreadMain(activeAccount!.name, body, pollOptions, dimensions)
        .then(operation => {
          createFakeThread(pushToStart, operation, 0, activeAccount!);

          toast(`Your thread re-thread is sucessfully published.`, {
            type: "success",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          });
        })
        .catch(() => {
          toast(`Failed to publish your re-thread.`, {
            type: "error",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          });
        })
        .finally(() => {
          setEditThread(false);
          setReThread(false);
          setOpen(false);
        });
    };

    const handleEdit = (newContent: string) => {
      editThreadOperation(threadContent, newContent)
        .then(() => {
          setEditThread(false);
          toast(
            `Your thread ${threadContent.content.permlink} is successfully updated.`,
            {
              type: "success",
              theme: isDarkMode ? "dark" : "light",
              autoClose: 3_000
            }
          );
        })
        .catch(() => {
          toast(`Failed to update thread ${threadContent.content.permlink}.`, {
            type: "error",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          });
        });
    };

    const { reference, floating, context } = useFloating({
      open: open,
      onOpenChange: setOpen,
      middleware: [flip(), hide()]
    });

    const dismiss = useDismiss(context, {
      ancestorScroll: true
    });

    const role = useRole(context, { role: "menu" });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      role,
      dismiss
    ]);

    useEffect(() => {
      console.log("EditThread HERE", editThread);
    }, [editThread]);

    return (
      <div ref={reference} {...getReferenceProps()} className="z-[101]">
        <FloatingPortal>
          {editThread && (
            <FloatingHTMLOverlay
              onClick={ev => ev.stopPropagation()}
              className="bg-overlay z-[1000] flex justify-center py-[10vh]"
              lockScroll
            >
              <div className="relative py-8 pc:py-4 px-6 flex flex-col gap-y-6 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-full h-fit pc:w-4/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full min-w-[340px] pc:min-w-[480px]">
                <header className="flex items-center justify-between pb-4 border-b border-pri dark:border-pri-d">
                  <strong className="text-lg">Edit Thread</strong>
                  <div
                    onClick={() => setEditThread(false)}
                    className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </header>

                <div className="relative  flex flex-col border-8 border-pri dark:border-pri-d border-b-0">
                  <MarkdownEditor
                    type={MarkdownEditorTypes.Thread}
                    initialContent={threadContent?.content?.body}
                    handlePost={handleEdit}
                  />
                </div>
              </div>
            </FloatingHTMLOverlay>
          )}
        </FloatingPortal>

        <FloatingPortal>
          {reThread && (
            <FloatingHTMLOverlay
              onClick={ev => ev.stopPropagation()}
              className="duration-50 backdrop-blur-sm bg-overlay z-[1000] flex justify-center py-[10vh]"
              lockScroll
            >
              <FloatingFocusManager context={context} initialFocus={-1}>
                <div className="animate-reveal-form relative flex-0 bg-pri dark:bg-pri-d w-full m-4 pc:m-0 pc:w-160 rounded-xl border border-pri dark:border-pri-d h-min">
                  <div
                    onClick={() => setReThread(false)}
                    className="absolute p-2 right-4 top-4 h-10 w-10 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                  <span className="left-4 px-4 absolute items-center justify-center font-bold text-xl mt-6">
                    Re-thread
                  </span>
                  <div className="px-4 py-6 pt-10">
                    <MarkdownEditor
                      type={MarkdownEditorTypes.Thread}
                      initialContent={`https://inleo.io/threads/view/${threadContent.content.author}/${threadContent.content.permlink}`}
                      handlePost={handlePost}
                    />
                  </div>
                </div>
              </FloatingFocusManager>
            </FloatingHTMLOverlay>
          )}
        </FloatingPortal>

        {open && (
          <FloatingPortal>
            <div
              ref={floating}
              className="fixed w-max min-w-[180px] right-0 mt-2 p-1 flex flex-col rounded-lg bg-pri dark:bg-pri-d shadow-[0_0_8px_rgb(255_255_255_/_17%)] border border-pri dark:border-pri-d z-[10001]"
              style={{ top: cursor.y, left: cursor.x }}
              // {...getReferenceProps()}
              {...getFloatingProps()}
            >
              {/* public actions */}
              <div
                className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "copylink")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
                Copy Link
              </div>

              <div
                className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "newtab")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
                Open in New Tab
              </div>

              <div
                className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "downvote")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faArrowDown} />
                </span>
                Downvote
              </div>

              <div
                className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "rethread")}
              >
                <span className="flex w-6 mr-2">
                  <FontAwesomeIcon icon={faRepeat} />
                </span>
                Re-thread
              </div>

              <div
                className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                onClick={event => handleDropdownItemAction(event, "tweet")}
              >
                <span className="flex w-6 mr-2">
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    fill="currentColor"
                    className="w-[14px] h-[14px]"
                  >
                    <g>
                      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                    </g>
                  </svg>
                </span>
                Tweet
              </div>

              {/* premium actions */}
              {premiumState.is_premium && (
                <div
                  className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                  onClick={event => handleDropdownItemAction(event, "list")}
                >
                  <span className="flex w-6 mr-2">
                    <FontAwesomeIcon icon={faFileLines} />
                  </span>
                  Add to Lists
                </div>
              )}

              {/* private actions (owner) */}
              {threadContent.thread.author === activeAccount?.name && (
                <React.Fragment>
                  <hr className="bg-pri-d dark:bg-pri my-2 opacity-10" />

                  <div
                    className="flex items-center w-full p-3 font-semibold text-sm text-gray-900 dark:text-gray-300 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                    onClick={event => handleDropdownItemAction(event, "edit")}
                  >
                    <span className="flex w-6 mr-2">
                      <FontAwesomeIcon icon={faEdit} />
                    </span>
                    Edit
                    <PremiumOnlyBadge className="ml-auto" />
                  </div>

                  <div
                    className="flex items-center w-full p-3 font-semibold text-sm text-red-500 dark:text-red-500 rounded-lg hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] transition-colors cursor-pointer"
                    onClick={event => handleDropdownItemAction(event, "delete")}
                  >
                    <span className="flex w-6 mr-2">
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                    Delete
                  </div>
                </React.Fragment>
              )}
            </div>
          </FloatingPortal>
        )}
      </div>
    );
  }
);

ControlledThreadOptions.displayName = "ControlledThreadOptions";
export default ControlledThreadOptions;

