import classNames from "classnames";
import { useHiveContent, HiveFetchState } from "~/hooks/contentHooks";
import type { ThreadIndexed } from "~/utils/thread";
import { FullThread } from "./FullThread";
import { LoadingThread } from "./LoadingThreads";

interface OnlyReplyProps {
  author: string;
  permlink: string;
  notop: boolean;
  continues: boolean;
}

export default function LoadOnlyReply({
  author,
  permlink,
  continues,
  notop
}: OnlyReplyProps) {
  const [state, threadContent] = useHiveContent({ author, permlink });
  if (threadContent === undefined) return null;
  if (state === HiveFetchState.Initial)
    return (
      <LoadingThread threadIndexed={{ author, permlink } as ThreadIndexed} />
    );
  if (!threadContent)
    return (
      <LoadingThread threadIndexed={{ author, permlink } as ThreadIndexed} />
    );
  return (
    <div className="flex flex-col relative">
      {!notop && (
        <div
          className="w-[1px]
            sm:left-[39.5px] md:left-[39.5px] left-[30.5px] pc:left-[39.5px] -top-4
            -z-10 h-7 absolute bg-zinc-300 dark:bg-zinc-700 rounded-full "
        />
      )}
      <FullThread
        continues={continues}
        norecursive={true}
        threadContent={
          //@ts-ignore
          { thread: threadContent, content: threadContent }
        }
      />
    </div>
  );
}

interface ThreadContainerProps {
  children: React.ReactNode;
  className?: string;
}
export function ThreadContainer({ children, className }: ThreadContainerProps) {
  return (
    <div
      className={classNames(
        "flex py-3.5 px-3 sm:hover:bg-pri-hov sm:dark:hover:bg-pri-hov-d cursor-pointer transition-colors ",
        className
      )}
    >
      {children}
    </div>
  );
}

interface ThreadBodyProps {
  children: React.ReactNode;
}

export function ThreadBody({ children }: ThreadBodyProps) {
  return (
    <div className="relative flex-1 min-w-0 flex flex-col gap-y-2">
      {children}
    </div>
  );
}

interface ThreadHeaderProps {
  children: React.ReactNode;
}

export function ThreadHeader({ children }: ThreadHeaderProps) {
  return (
    <div className="h-max flex flex-row justify-between gap-x-2 items-center">
      {children}
    </div>
  );
}

interface ThreadFooterProps {
  children: React.ReactNode;
}

export function ThreadFooter({ children }: ThreadFooterProps) {
  return (
    <div
      onClick={ev => ev.stopPropagation()}
      className="flex items-center gap-1 pt-3 my-0.5"
    >
      {children}
    </div>
  );
}

interface ThreadTextProps {
  children: React.ReactNode;
}

export function ThreadText({ children }: ThreadTextProps) {
  return (
    <div className="list-disc prose dark:prose-invert prose-lg sm:prose-sm break-words min-w-full max-w-0 !text-lg">
      {children}
    </div>
  );
}

