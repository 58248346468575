import type { HiveContent } from "~/utils/hive";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import IconText from "~/components/IconText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FloatingFocusManager,
  FloatingPortal,
  useFloating,
  useInteractions,
  useRole
} from "@floating-ui/react";
import {
  Suspense,
  lazy,
  memo,
  useContext,
  useState,
  useRef,
  useEffect
} from "react";
import { ThreadView } from "~/components/threads/ThreadView";
import { ThreadFocusView } from "../threads/FullFocusThreadView";
import type { PossibleThreadContent, ThreadContent } from "~/utils/thread";
import { MarkdownEditorTypes } from "~/components/publish/MarkdownEditorTypes";
import { InReplyModalContext } from "~/contexts";
import classNames from "classnames";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";
import { postThreadReply } from "~/utils/transactions";
import { createFakeThread } from "~/routes/threads";
import { usePushArrayState } from "~/hooks/arrayStateHooks";
import { useNavigate } from "@remix-run/react";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import { useAppStore } from "~/store";
import {
  Drawer,
  DrawerTrigger,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerFooter,
  DrawerClose
} from "../Drawer";
import useOnClickOutside from "~/hooks/useClickOutside";
import MarkdownEditor from "../MarkdownEditor";
import { Sheet } from "react-modal-sheet";
import { useStrippedSlicedBody } from "~/utils/markdown";
import { TimeSincePublish } from "../TimeSincePublish";
import { AnimatePresence, cubicBezier, motion } from "framer-motion";

interface ReplyCountProps {
  replyCount: HiveContent["children"];
  threadContent?: ThreadContent;
  redirectToPost?: boolean;
  redirectURL?: string;
  className?: string;
}

const ReplyCount = memo(
  ({
    replyCount,
    threadContent,
    redirectToPost = false,
    redirectURL,
    className
  }: ReplyCountProps) => {
    const navigate = useNavigate();
    const isDesktop = useMediaQuery("(pointer: fine)");
    const [scrollPos, setScrollPos] = useState(0);
    const [open, setOpen] = useState(false);

    // TODO: extract this as generic modal component
    const { reference, floating, context } = useFloating({
      open,
      onOpenChange: setOpen
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
      useRole(context)
    ]);

    const inReplyModal = useContext(InReplyModalContext);
    const activeAccount = useAppStore(store => store.account.activeAccount);

    const [fakeThreads, setFakeThreads] = useState(
      [] as PossibleThreadContent[]
    );
    const pushToFakeThreads = usePushArrayState(setFakeThreads);

    const handlePost = async (body: string, pollOptions: Object) => {
      if (threadContent === undefined || !activeAccount) {
        return;
      }
      const operation = await postThreadReply(
        activeAccount?.name,
        body,
        threadContent.thread,
        false,
        pollOptions
      );
      const index =
        fakeThreads?.length > 0
          ? fakeThreads[fakeThreads?.length - 1].thread.index + 1
          : 0;
      createFakeThread(pushToFakeThreads, operation, index, activeAccount!);
      setOpen(false);
    };

    const handleClick = (ev: Event) => {
      // ev.stopPropagation();
      isDesktop && ev.preventDefault();

      if (redirectToPost && redirectURL) {
        return navigate(redirectURL);
      }

      if (activeAccount === null) {
        return;
      }
      if (inReplyModal) {
        return;
      }
      if (threadContent === undefined) {
        return;
      }

      setOpen(true);
    };
    const dialogRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(dialogRef, () => setOpen(false));

    // useEffect(() => {
    //   if (open) {
    //     const html = document.getElementsByTagName("html")[0];
    //     const body = document.getElementsByTagName("body")[0];
    //     html.style.overflow = "hidden";
    //     html.style.touchAction = "none";
    //     body.style.touchAction = "none";
    //   } else {
    //     const html = document.getElementsByTagName("html")[0];
    //     const body = document.getElementsByTagName("body")[0];
    //     html.style.overflow = "auto";
    //     html.style.touchAction = "auto";
    //     body.style.overflow = "auto";
    //     body.style.touchAction = "auto";
    //   }
    // }, [open]);

    const body = useStrippedSlicedBody(threadContent?.thread?.body, 200);

    if (isDesktop) {
      return (
        <div
          role="button"
          aria-roledescription="Reply"
          ref={reference}
          onTouchEnd={handleClick as any}
          onClick={handleClick as any}
          {...getReferenceProps()}
        >
          <span className="group/replycount flex text-lg items-center mx-0.5 pc:mx-0 py-1.5 pl-1.5 pc:py-0.5 pc:pl-0.5 pr-2 rounded-full text-gray-500 dark:text-zinc-500 transition-colors cursor-pointer">
            <div
              className={classNames(
                "flex justify-center items-center rounded-full w-9 h-9 sm:w-8 sm:h-8 group-hover/replycount:bg-acc/[.15] group-hover/replycount:text-acc transition-all duration-150",
                className
              )}
            >
              <FontAwesomeIcon
                icon={faComment}
                className="w-5 h-5"
                fixedWidth
              />
            </div>
            <span className="pl-1 font-normal text-lg sm:text-base group-hover/replycount:text-acc transition-all duration-150">
              {replyCount || 0}
            </span>
          </span>
          <FloatingPortal>
            {open && (
              <FloatingHTMLOverlay
                onClick={ev => ev.stopPropagation()}
                className="duration-50 backdrop-blur-sm bg-black/20 z-[1000] flex justify-center py-[10vh] px-4"
              >
                <FloatingFocusManager context={context} initialFocus={-1}>
                  <div
                    className="animate-reveal-form relative flex-0 bg-pri dark:bg-pri-d w-full m-4 pc:m-0 pc:w-160 rounded-xl border border-pri dark:border-pri-d h-min drop-shadow-lg shadow-[0_0_12px_3px_rgb(255_255_255_/_17%)]"
                    ref={floating}
                    {...getFloatingProps()}
                  >
                    <div
                      onClick={() => setOpen(false)}
                      className="absolute p-2 right-4 top-4 h-10 w-10 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                    <div className="px-4 py-6 pt-10">
                      <InReplyModalContext.Provider value={true}>
                        <ThreadFocusView threadContent={threadContent!} />
                        <MarkdownEditor
                          type={MarkdownEditorTypes.Thread}
                          handlePost={handlePost}
                          autoFocus={true}
                        />
                        {fakeThreads?.length > 0 && (
                          <div className="py-3">
                            {fakeThreads.map((fakeThreadContent, i) => (
                              <ThreadView
                                key={i}
                                threadContent={fakeThreadContent}
                                fake={true}
                              />
                            ))}
                          </div>
                        )}
                      </InReplyModalContext.Provider>
                    </div>
                  </div>
                </FloatingFocusManager>
              </FloatingHTMLOverlay>
            )}
          </FloatingPortal>
        </div>
      );
    } else {
      return (
        // <InReplyModalContext.Provider value={true}>
        //   <div
        //     role="button"
        //     aria-roledescription="Reply"
        //     ref={reference}
        //     onTouchEnd={handleClick as any}
        //     onClick={handleClick as any}
        //     {...getReferenceProps()}
        //   >
        //     <IconText>
        //       <div
        //         className={classNames(
        //           "flex justify-center items-center rounded-full w-9 h-9 sm:w-8 sm:h-8 group-hover:bg-acc/[.15] group-hover:text-acc transition-all duration-150",
        //           className
        //         )}
        //       >
        //         <FontAwesomeIcon
        //           icon={faComment}
        //           className="w-5 h-5"
        //           fixedWidth
        //         />
        //       </div>
        //       <span className="pl-1 font-normal text-lg sm:text-base group-hover:text-acc transition-all duration-150">
        //         {replyCount || 0}
        //       </span>
        //     </IconText>
        //   </div>
        //   <Sheet
        //     detent="content-height"
        //     isOpen={open}
        //     onClose={() => setOpen(false)}
        //   >
        //     <Sheet.Container>
        //       <Sheet.Header className="bg-pri dark:bg-zinc-900 rounded-t-[10px] overflow-hidden" />
        //       <Sheet.Content className="bg-pri dark:bg-zinc-900">
        //         <Sheet.Scroller className="w-full flex flex-col overflow-y-auto bg-pri dark:bg-pri-d">
        //           <div className="h-full flex flex-col overflow-y-auto pb-[env(keyboard-inset-height,_50px)]">
        //             <div className="w-full h-max-[250px]">
        //               {" "}
        //               <div className="w-full mx-auto my-4 p-4">
        //                 <div className="flex items-center">
        //                   <div
        //                     onClick={() =>
        //                       document
        //                         .getElementById("thread-markdown")
        //                         ?.focus()
        //                     }
        //                     className="relative flex w-14 h-14 rounded-full overflow-hidden"
        //                   >
        //                     <img
        //                       src={`https://img.inleo.io/u/${threadContent?.thread?.author}/avatar/small`}
        //                       className="w-full h-full object-center object-cover"
        //                       alt=""
        //                     />
        //                   </div>
        //                   <div className="ml-3">
        //                     <span className="font-semibold text-lg">
        //                       {threadContent?.thread?.author}
        //                     </span>
        //                     <span className="text-sm text-gray-500 ml-2">
        //                       <TimeSincePublish
        //                         publishTime={threadContent?.thread?.created}
        //                       />
        //                     </span>
        //                   </div>
        //                 </div>
        //                 <p className="mt-4 text-xl antialiased line-clamp-5 leading-loose w-full">
        //                   {body}
        //                 </p>{" "}
        //                 <div className="mt-6">
        //                   <span className="text-gray-500">Replying to </span>
        //                   <a
        //                     href={`https://inleo.io/@${threadContent?.thread?.author}`}
        //                     className="text-acc hover:underline"
        //                   >
        //                     @{threadContent?.thread?.author}
        //                   </a>
        //                 </div>
        //               </div>
        //             </div>
        //             <div className="min-h-[250px] max-h-[400px] overflow-auto">
        //               <MarkdownEditor
        //                 type={MarkdownEditorTypes.Thread}
        //                 handlePost={handlePost}
        //                 autoFocus={true}
        //               />
        //             </div>
        //             {fakeThreads?.length > 0 && (
        //               <div className="py-3">
        //                 {fakeThreads.map((fakeThreadContent, i) => (
        //                   <ThreadView
        //                     key={i}
        //                     threadContent={fakeThreadContent}
        //                   />
        //                 ))}
        //               </div>
        //             )}
        //           </div>
        //         </Sheet.Scroller>
        //       </Sheet.Content>
        //     </Sheet.Container>
        //     <Sheet.Backdrop />
        //   </Sheet>
        // </InReplyModalContext.Provider>

        <InReplyModalContext.Provider value={true}>
          <div
            role="button"
            aria-roledescription="Reply"
            ref={reference}
            onTouchEnd={handleClick as any}
            onClick={handleClick as any}
            {...getReferenceProps()}
          >
            <span className="group/replycount flex text-lg items-center mx-0.5 pc:mx-0 py-1.5 pl-1.5 pc:py-0.5 pc:pl-0.5 pr-2 rounded-full text-gray-500 dark:text-zinc-500 transition-colors cursor-pointer">
              <div
                className={classNames(
                  "flex justify-center items-center rounded-full w-9 h-9 sm:w-8 sm:h-8 group-hover/replycount:bg-acc/[.15] group-hover/replycount:text-acc transition-all duration-150",
                  className
                )}
              >
                <FontAwesomeIcon
                  icon={faComment}
                  className="w-5 h-5"
                  fixedWidth
                />
              </div>
              <span className="pl-1 font-normal text-lg sm:text-base group-hover/replycount:text-acc transition-all duration-150">
                {replyCount || 0}
              </span>
            </span>
          </div>

          <FloatingPortal>
            <AnimatePresence mode="popLayout">
              {open && (
                <motion.div
                  style={{
                    height: "100dvh",
                    minHeight: "webkit-fill-available"
                  }}
                  initial={{ y: "100%" }}
                  animate={{ y: 0 }}
                  exit={{ y: "100%" }}
                  transition={{
                    duration: 0.3,
                    ease: cubicBezier(0.62, 0.05, 0.01, 0.99)
                  }}
                  className="fixed inset-0 top-0 bottom-0 left-0 flex flex-col w-screen max-h-screen bg-pri dark:bg-pri-d z-[1002]"
                  tabIndex={1}
                >
                  <header className="flex justify-between items-center w-full py-2.5 pr-4 pl-5 border-b border-pri dark:border-pri-d">
                    <span
                      onClick={() => setOpen(false)}
                      className="text-[15px] font-medium"
                    >
                      Cancel
                    </span>

                    {/* <button
                      type="button"
                      className="flex py-2 px-4 rounded-full bg-acc text-pri font-medium text-[13px]"
                    >
                      Thread
                    </button> */}
                  </header>

                  <div className="flex flex-col flex-1 overflow-y-auto">
                    <div className="w-full">
                      <div className="relative w-full mx-auto my-4 p-4">
                        <div className="flex items-center">
                          <div
                            onClick={() =>
                              document
                                .getElementById("thread-markdown")
                                ?.focus()
                            }
                            className="relative flex w-12 h-12 rounded-full overflow-hidden"
                          >
                            <img
                              src={`https://img.inleo.io/u/${threadContent?.thread?.author}/avatar/small`}
                              className="w-full h-full object-center object-cover"
                              alt=""
                            />
                          </div>

                          <div className="flex flex-col ml-3">
                            <span className="font-semibold text-lg">
                              {threadContent?.thread?.author}
                            </span>
                            <span className="text-sm text-gray-500">
                              <TimeSincePublish
                                publishTime={threadContent?.thread?.created}
                              />
                            </span>
                          </div>
                        </div>

                        <p className="mt-4 pl-12 ml-3 line-clamp-5 leading-[1.415] text-[13px] w-full">
                          {body}
                        </p>

                        <div className="mt-6 pl-12 ml-3">
                          <span className="text-gray-500">Replying to </span>
                          <a
                            href={`https://inleo.io/@${threadContent?.thread?.author}`}
                            className="text-acc hover:underline"
                          >
                            @{threadContent?.thread?.author}
                          </a>
                        </div>

                        <span className="absolute top-20 left-10 bottom-0 w-px bg-gray-200 dark:bg-zinc-800 rounded-full" />
                      </div>
                    </div>
                    <div className="pb-32 z-[10001]">
                      <MarkdownEditor
                        type={MarkdownEditorTypes.Thread}
                        handlePost={handlePost}
                        className="border-none"
                        autoFocus={true}
                      />
                    </div>
                    {fakeThreads?.length > 0 && (
                      <div className="py-3">
                        {fakeThreads.map((fakeThreadContent, i) => (
                          <ThreadView
                            key={i}
                            threadContent={fakeThreadContent}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </FloatingPortal>
        </InReplyModalContext.Provider>
      );
    }
  }
);

ReplyCount.displayName = "ReplyCount";
export default ReplyCount;
